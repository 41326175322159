import { router } from "@/main";
import { Cookies } from "@/types/Cookies";
import axios, { AxiosError } from "axios";
import { useCookies } from "vue3-cookies";
import { config } from "@/config";

const apiUrl = "https://ffr-garage.fr/api/";

console.log(apiUrl);

const instance = axios.create({
  baseURL: apiUrl || "http://localhost:3000",
  timeout: Number(config.timeout) || 10000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
});

instance.interceptors.request.use(
  function (config) {
    const { cookies } = useCookies();
    const token = cookies.get(Cookies.CONNECTION_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // TODO faire quelque chose en cas d’erreur
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // n’importe quel code de réponse HTTP dans la plage 2xx activera cette
    // fonction
    // faire quelque chose avec les données de la réponse
    return response;
  },
  function (error) {
    if (error.response?.data.message.match(/Invalid or expired Token/gi)) {
      // store.commit(`user/${MUTATION_TYPES.DISCONNECT}`);
      return router.push({ path: "/" });
      //TODO: faire la deconnexion si plus de token
    }
    return Promise.reject(error);
  }
);

export const getAxiosError = (
  error: Error | AxiosError
): { statusCode: number; message: string } | Error => {
  if (axios.isAxiosError(error) && error.response) {
    return error.response.data as { statusCode: number; message: string };
  }
  return error;
};

export { instance as axios };
