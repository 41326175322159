import { axios } from "@/libs/axios";
import { MUTATION_TYPES } from "./mutation-types";
import { ActionContext, ActionTree, GetterTree, MutationTree } from "vuex";
import { SendFormContact, SendFormAppointment } from "./type";
import { RootState } from "@/store";

export interface ContactState {
  isOk: string;
}

const state = (): ContactState => ({
  isOk: "false",
});

const getters: GetterTree<ContactState, RootState> = {
  isOk: (state) => state.isOk,
};

const actions: ActionTree<ContactState, RootState> = {
  async sendFormContact(
    context: ActionContext<ContactState, RootState>,
    { lastName, firstName, ...contact }: SendFormContact
  ) {
    try {
      await axios.post("/contact", {
        last_name: lastName,
        first_name: firstName,
        ...contact,
      });
    } catch (e: any) {
      console.log(e);
      throw "Une erreur s'est produite";
    }
  },
  async sendFormAppointment(
    context: ActionContext<ContactState, RootState>,
    {
      lastName,
      firstName,
      licencePlate,
      slotSelected,
      prestationsSelected,
      complementaryInformations,
      ...appointment
    }: SendFormAppointment
  ) {
    try {
      await axios.post("new-appointment", {
        last_name: lastName,
        first_name: firstName,
        licence_plate: licencePlate,
        slot: slotSelected,
        prestations: prestationsSelected,
        complementary_informations: complementaryInformations,
        ...appointment,
      });
    } catch (e: any) {
      throw "Une erreur s'est produite";
    }
  },
};

const mutations: MutationTree<ContactState> = {
  [MUTATION_TYPES.SET_IS_OK](state, isOk) {
    state.isOk = isOk;
  },
};

const namespaced = true;

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced,
};
